import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BasicModal, MoneyCell, LoginCell, DefaultButton, Tooltip } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { getAccountTradeLink, getConvertationOptions } from 'client/accounts/_redux';
import DemoDepositForm from '../DemoDepositForm';
import LeverageCell from '../LeverageCell';
import AccountConvertForm from '../AccountConvertForm';
import Brokeree from '../Brokeree';

import { staticStyles, getDynamicStyles } from './style';

const AccountInfoPanel = ({
  id,
  balance,
  equity,
  totalWithdrawals,
  totalDeposits,
  profit,
  accountTypeId,
  profitPercent,
  marginFree,
  leverage,
  loadingFailed,
  loading,
  theme,
  login,
  userLogin,
  isDemo,
  brokereeConfig,
  isBinary,
  accountTypeTitle,
  accountTypeServer,
  isDemoDepositEnabled,
  partnershipAccount,
  accountTypeServerId,
  onLeverageChangeClick,
  onPasswordChangeClick,
  getAccountTradeLink,
  getConvertationOptions,
  convertationOptions,
  convertationOptionsAreLoaded,
  addons,
  isBlocked,
  modals,
  closeModal,
  openModal,
  webTerminal,
  equityEnable,
  platformTitle,
  isDepositEnabled,
  isWithdrawEnabled,
  history,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleConvertModal = () => {
    toggleModal();
  };

  const isConvertable = addons.includes('accountTypeConvertation');

  useEffect(() => {
    if (isConvertable) {
      getConvertationOptions(id);
    }
  }, [id, getConvertationOptions, isConvertable]);

  const isBrokereeManager = brokereeConfig ? !!brokereeConfig.manager_types[accountTypeId] : false;

  const [brokereeLink, setBrokereeLink] = useState(null);

  useEffect(() => {
    if (!brokereeConfig) return;
    if (brokereeConfig.manager_types[accountTypeId]) {
      axios
        .post('/utils/brokeree/login/', { login, accountTypeId, userType: 'Manager' })
        .then(({ data: tokenResponse }) => {
          setBrokereeLink(tokenResponse.link);
        })
        .catch(() => {
          setBrokereeLink(brokereeConfig.app_link);
        });
    } else if (brokereeConfig.investor_types[accountTypeId]) {
      axios
        .post('/utils/brokeree/login/', {
          login,
          accountTypeId,
          userType: 'Investor',
        })
        .then(({ data: tokenResponse }) => setBrokereeLink(tokenResponse.link))
        .catch(() => {
          setBrokereeLink(brokereeConfig.app_link);
        });
    }
  }, []);

  return (
    <div className="AccountInfoPanel__wrapper">
      <div className="AccountInfoPanel AccountInfoPanel__top">
        <ul>
          <li>
            <FormattedMessage id="justType">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            <LoginCell title={accountTypeTitle} />
          </li>
          <li>
            <FormattedMessage id="justBalance">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            <MoneyCell
              tooltipId={`AccountInfoPanel-justBalance-${id}`}
              value={balance}
              loading={loading}
              loadingFailed={loadingFailed}
            />
          </li>
          {equityEnable && (
            <li>
              <FormattedMessage id="justEquity">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell
                tooltipId={`AccountInfoPanel-justEquity-${id}`}
                value={equity}
                loading={loading}
                loadingFailed={loadingFailed}
              />
            </li>
          )}
          {['MetaTrader 4', 'MetaTrader 5', 'DXTrade'].includes(platformTitle) && equityEnable && (
            <li>
              <FormattedMessage id="justAvailableToWithdraw">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell
                tooltipId={`AccountInfoPanel-justAvailableToWithdraw-${id}`}
                value={marginFree}
                loading={loading}
                loadingFailed={loadingFailed}
              />
            </li>
          )}
          <li className="Leverage__wrapper">
            <FormattedMessage id="justLeverage">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            {!partnershipAccount ? (
              <LeverageCell
                loading={loading}
                isBlocked={loading || isBlocked}
                leverage={leverage}
                onLeverageChangeClick={onLeverageChangeClick}
              />
            ) : (
              <span className="AccountInfoPanel__second-line">
                {!loading ? <p>{leverage ? `1:${leverage}` : '—'}</p> : '...'}
              </span>
            )}
          </li>
          {!partnershipAccount && (
            <li className="AccountInfoPanel__payments-buttons">
              {!isDemo ? (
                <>
                  {isBrokereeManager && (
                    <>
                      <DefaultButton
                        textId="justNewPammFund"
                        onClick={() => {
                          history.push('/create-account/brokeree-manager');
                        }}
                        filled
                        plus
                        disabled={loading || isBlocked}
                      />
                      <DefaultButton
                        textId="justTradePammFund"
                        onClick={() => {
                          window.open(brokereeLink);
                        }}
                        filled
                        disabled={loading || isBlocked}
                      />
                    </>
                  )}
                  {props.onDepositClick && isDepositEnabled && !isBrokereeManager && (
                    <Tooltip id="clientDepositTooltip" textId={isBlocked ? 'clientDepositBlockedAccount' : ''}>
                      <DefaultButton
                        textId="clientDeposit"
                        onClick={() => props.onDepositClick(id)}
                        filled
                        disabled={loading || isBlocked}
                      />
                    </Tooltip>
                  )}
                  {isWithdrawEnabled && (
                    <Tooltip id="clientWithdrawTooltip" textId={isBlocked ? 'clientWithdrawBlockedAccount' : ''}>
                      <DefaultButton
                        textId="justWithdraw"
                        onClick={() => props.onWithdrawClick(id)}
                        disabled={loading || isBlocked}
                      />
                    </Tooltip>
                  )}
                </>
              ) : (
                <DefaultButton
                  textId="clientDeposit"
                  onClick={e => {
                    e.stopPropagation();
                    openModal('depositToDemoAccount');
                  }}
                  disabled={!isDemoDepositEnabled}
                />
              )}

              {isConvertable && convertationOptionsAreLoaded && !!convertationOptions.length && (
                <DefaultButton textId="justConvert" butColor="warning" onClick={() => handleConvertModal()} />
              )}
            </li>
          )}
        </ul>
      </div>
      {profit && totalDeposits && totalWithdrawals && (
        <div className="AccountInfoPanel AccountInfoPanel--MarginBottom AccountInfoPanel__bottom">
          <ul>
            <li>
              <FormattedMessage id="justProfitInUSD">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell tooltipId={`AccountInfoPanel-justTotalProfit-${id}`} value={profit} />
            </li>

            <li>
              <FormattedMessage id="justProfitInPercent">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell
                tooltipId={`AccountInfoPanel-justTotalProfitInPercent-${id}`}
                value={{ amount: profitPercent, currency: '%' }}
                maxDigitsAfterDecimal={4}
              />
            </li>

            <li className="Account__partner-code">
              <FormattedMessage id="justTotalDeposits">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell tooltipId={`AccountInfoPanel-justTotalDeposits-${id}`} value={totalDeposits} />
            </li>

            <li>
              <FormattedMessage id="justTotalWithdrawals">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell tooltipId={`AccountInfoPanel-justTotalWithdrawals-${id}`} value={totalWithdrawals} />
            </li>
          </ul>
        </div>
      )}

      {brokereeLink && <Brokeree brokereeLink={brokereeLink} />}

      {!partnershipAccount && (
        <>
          <div className="AccountInfoPanel AccountInfoPanel__bottom">
            <ul>
              <li>
                <FormattedMessage id="accountLogin">
                  {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
                </FormattedMessage>
                <span className="AccountInfoPanel__second-line">{login}</span>
              </li>
              {props.partnerCode && (
                <li className="Account__partner-code">
                  <FormattedMessage id="partnerCode">
                    {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
                  </FormattedMessage>
                  <span className="AccountInfoPanel__second-line">{props.partnerCode}</span>
                </li>
              )}
              <li>
                <FormattedMessage id="accountServer">
                  {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
                </FormattedMessage>
                <span className="AccountInfoPanel__second-line">{accountTypeServer}</span>
              </li>
              {!isBinary && (
                <li>
                  <FormattedMessage id="authPassword">
                    {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
                  </FormattedMessage>

                  <div className="AccountInfoPanel__second-line">
                    <Tooltip
                      id="clientPasswordChangeTooltip"
                      textId={isBlocked ? 'clientChangePasswordBlockedAccount' : ''}>
                      <DefaultButton
                        textId="clientPasswordChange"
                        onClick={onPasswordChangeClick}
                        noBorder
                        disabled={loading || isBlocked}
                      />
                    </Tooltip>
                  </div>
                </li>
              )}
              {webTerminal && (
                <li>
                  <Tooltip id="clientTradeTooltip" textId={isBlocked ? 'clientTradeBlockedAccount' : ''}>
                    <DefaultButton
                      textId="clientTrade"
                      filled
                      onClick={() => getAccountTradeLink({ accountId: id })}
                      disabled={loading || isBlocked}
                    />
                  </Tooltip>
                </li>
              )}
            </ul>
          </div>

          <BasicModal
            captionId="depositToDemoAccount"
            isOpen={modals.depositToDemoAccount}
            onRequestClose={() => closeModal('depositToDemoAccount')}>
            {modals.depositToDemoAccount && (
              <DemoDepositForm accountId={id} onSuccessfulDeposit={() => closeModal('depositToDemoAccount')} />
            )}
          </BasicModal>
          {isConvertable && convertationOptionsAreLoaded && !!convertationOptions.length && (
            <BasicModal isOpen={isOpen} captionId="accountTypeConvertation" onRequestClose={toggleModal}>
              <AccountConvertForm id={id} options={convertationOptions} toggleModal={toggleModal} />
            </BasicModal>
          )}
        </>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

AccountInfoPanel.propTypes = {
  totalWithdrawals: MoneyCell.propTypes.value,
  totalDeposits: MoneyCell.propTypes.value,
  profit: MoneyCell.propTypes.value,
  profitPercent: PropTypes.string,
  accountTypeId: PropTypes.number.isRequired,
  accountTypeTitle: PropTypes.string.isRequired,
  accountTypeServer: PropTypes.string.isRequired,
  brokereeConfig: PropTypes.object,
  id: PropTypes.number.isRequired,
  balance: MoneyCell.propTypes.value,
  equity: MoneyCell.propTypes.value,
  marginFree: MoneyCell.propTypes.value,
  leverage: PropTypes.number,
  loadingFailed: PropTypes.bool,
  loading: PropTypes.bool,
  onDepositClick: PropTypes.func,
  onWithdrawClick: PropTypes.func,
  login: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
  isDemoDepositEnabled: PropTypes.bool.isRequired,
  getAccountTradeLink: PropTypes.func.isRequired,
  getConvertationOptions: PropTypes.func.isRequired,
  convertationOptions: PropTypes.array.isRequired,
  convertationOptionsAreLoaded: PropTypes.bool.isRequired,
  platformTitle: PropTypes.string.isRequired,
  equityEnable: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  userLogin: PropTypes.string,
  partnershipAccount: PropTypes.bool,
  onLeverageChangeClick: PropTypes.func,
  onPasswordChangeClick: PropTypes.func,
  isBlocked: PropTypes.bool,
  modals: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  webTerminal: PropTypes.string,
  partnerCode: PropTypes.string,
  isBinary: PropTypes.bool,
  isDepositEnabled: PropTypes.bool.isRequired,
  isWithdrawEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

AccountInfoPanel.defaultProps = {
  totalWithdrawals: null,
  totalDeposits: null,
  profit: null,
  profitPercent: null,
  leverage: 0,
  balance: {},
  equity: {},
  marginFree: {},
  onDepositClick: null,
  onWithdrawClick: null,
  partnershipAccount: false,
  loadingFailed: false,
  onLeverageChangeClick: null,
  onPasswordChangeClick: null,
  isBlocked: false,
  loading: false,
  modals: {},
  closeModal: null,
  openModal: null,
  webTerminal: '',
  brokereeConfig: null,
  partnerCode: '',
  userLogin: '',
  isBinary: false,
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      addons: state.interfaceConfig.addons,
      brokereeConfig: state.interfaceConfig.brokereeConfig,
      convertationOptions: state.client.accounts.convertationOptions,
      convertationOptionsAreLoaded: state.client.accounts.convertationOptionsAreLoaded,
      equityEnable: state.interfaceConfig.equityTradingHistoryDisplay.equity,
      isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
      isWithdrawEnabled: state.interfaceConfig.paymentSettings.isWithdrawEnabled,
    }),
    {
      getAccountTradeLink: data => getAccountTradeLink.request(data),
      getConvertationOptions: id => getConvertationOptions.request(id),
    }
  )
)(AccountInfoPanel);
export { AccountInfoPanel };
